// $base-style
h1,
.h1 {
  display: block;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: $color-accent-1;
  letter-spacing: .01em;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5em;

  @include antialias();
}
h2,
.h2 {
  position: relative;
  display: block;
  margin-top: 2rem;
  margin-bottom: .5rem;
  color: $color-accent-2;
  text-transform: none;
  letter-spacing: normal;
  font-weight: bold;
  font-size: 1rem;
}
h3 {
  color: $color-accent-2;
  text-decoration: underline;
  font-weight: bold;
  font-size: .9rem;
}
h4
h5
h6 {
  display: inline;
  text-decoration: none;
  color: $color-accent-3;
  font-weight: bold;
  font-size: .9rem;
}
h3
h4
h5
h6 {
  margin-top: .9rem;
  margin-bottom: .5rem;
}
hr {
  border: .5px dashed $color-accent-3;
  opacity: .5;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
strong {
  font-weight: bold;
}
em
cite {
  font-style: italic;
}
sup
sub {
  position: relative;
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
}
sup {
  top: -.5em;
}
sub {
  bottom: -.2em;
}
small {
  font-size: .85em;
}
acronym
abbr {
  border-bottom: 1px dotted;
}
ul
ol
dl {
  line-height: $line-height;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
ol {
  list-style: decimal;
}
dt {
  font-weight: bold;
}
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: $font-size + 2px;
  overflow: auto;
  display: block;
}
th {
  padding: 8px;
  border-bottom: 1px dashed $color-border;
  color: $color-accent-2;
  font-weight: bold;
  font-size: $font-size + 2px;
}
td {
  padding: 0 8px;
  border-bottom: none;
}