
@font-face {
  font-style: normal;
  font-family: "JetBrains Mono";
  font-display: swap;
  src: local("JetBrains Mono"), local("JetBrains-Mono"), 
  url("../lib/JetBrainsMono/web/woff2/JetBrainsMono-Regular.woff2") format("woff2"), 
  url("../lib/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff") format("woff"), 
  url("../lib/JetBrainsMono/web/eot/JetBrainsMono-Regular.eot") format("embedded-opentype"), 
  url("../lib/JetBrainsMono/ttf/JetBrainsMono-Regular.ttf") format("truetype");
};